import React from "react";
import styles from './TrainerCard.module.css';

const TrainerCard = ({name, photoBase64, rating, location, tags = [], onClick}) => {

    function getShortTags(tags) {
        const tagsViewLimit = 2;
        if (tags && tags.length > tagsViewLimit) {
            var shortTags = tags.slice(0, tagsViewLimit);
            let child = tags.length - tagsViewLimit;
            if (child > 0) {
                shortTags.push('+ ' + child)
            }
            return shortTags;
        }
        return tags;
    }

    return (
        <div className={styles.card} onClick={() => onClick ? onClick() : null}>
            <div className={styles.photoContainer}>
                <img src={`data:image/jpeg;base64,${photoBase64}`} className={styles.trainerPhoto} alt="avatar"/>
            </div>
            <div className={styles.trainerInfoContainer}>
                <div className={styles.nameAndRating}>
                    <div className={styles.nameContainer}>
                        <span className={styles.name}>{name}</span>
                    </div>
                    <div className={styles.ratingContainer}>
                        <span className={styles.rating}>{rating}</span>
                        <img className={styles.starIcon} src="img/common/icons/star-black.svg" alt="star"/>
                    </div>
                </div>
                <address className={styles.address}>{location}</address>
                <div className={styles.activities}>
                    {getShortTags(tags).map((activity, index) => (
                        <div className={styles.activity}> {activity}</div>
                    ))}
                </div>
            </div>
        </div>);
};
export default TrainerCard;