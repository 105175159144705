import WideButton from "../common/WideButton";
import styles from "./Authentication.module.css"
import {useNavigate} from "react-router-dom";
import pages from '../../pages';
import React, {useEffect, useState} from 'react';
import * as authService from "../../services/authService";
import {handleRestError} from "../../services/restUtils";

const Authentication = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        authenticateUser();
    }, []);

    if (loading) {
        return <div></div>;
    }

    function authenticateUser() {
        authService.login()
            .then(() => {
                navigate(pages.home);
            })
            .catch(error => {
                handleRestError(error, navigate);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className={styles.authContainer} style={{backgroundImage: `url(img/registration/1_registration.png)`}}>
            <div className={styles.authButton}>
                <WideButton text="Авторизация" onClick={authenticateUser}/>
            </div>
        </div>
    );
}

export default Authentication;

