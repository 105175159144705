import React, {useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import pages from './pages';
import './App.css'
import Home from "./component/home/Home";
import Trainers from "./component/trainer/Trainers";
import Authentication from "./component/registration/Authentication";
import ChooseClientType from "./component/registration/ChooseClientType";
import RegistrationForm from "./component/registration/RegistrationForm";
import TrainerPage from "./component/trainer/TrainerPage";
import ProfilePage from "./component/profile/ProfilePage";
import apiTelegram from "./services/apiTelegram";
import NotFoundPage from "./component/error/NotFoundPage";
import ForbiddenPage from "./component/error/ForbiddenPage";
import SchedulePage from "./component/schedule/SchedulePage";
import TrainerWorkTimePage from "./component/trainer/TrainerWorkTimePage";
import ProfileDetailsPage from "./component/profile/ProfileDetailsPage";

const App = () => {

    useEffect(() => {
        apiTelegram.ready();
        apiTelegram.isVerticalSwipesEnabled = false;
    }, []);

    return (
        <div className="main">
            <div className="page">
                <BrowserRouter>
                    <Routes>
                        <Route exact path={pages.authentication} element={<Authentication/>}/>
                        <Route path={pages.chooseClientType} element={<ChooseClientType/>}/>
                        <Route path={pages.registration} element={<RegistrationForm/>}/>
                        <Route path={pages.home} element={<Home/>}/>
                        <Route path={pages.trainers} element={<Trainers/>}/>
                        <Route path={pages.trainerView} element={<TrainerPage/>}/>

                        <Route path={pages.profile} element={<ProfilePage/>}/>
                        <Route path={pages.profileDetails} element={<ProfileDetailsPage/>}/>

                        <Route path={pages.schedule} element={<SchedulePage/>}/>
                        <Route path={pages.trainerWorkTime} element={<TrainerWorkTimePage/>}/>
                        <Route path={pages.notFound} element={<NotFoundPage/>}/>
                        <Route path={pages.forbidden} element={<ForbiddenPage/>}/>
                        <Route path="*" element={<Navigate to={pages.forbidden} replace/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
        </div>
    )
};


export default App;