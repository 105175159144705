import React, {useEffect, useState} from 'react';
import styles from './ProfileDetailsPage.module.css';
import NavigationBar from "../common/NavigationBar";
import {useNavigate} from "react-router-dom";
import {getProfile} from "../../services/clientService";
import {handleRestError} from "../../services/restUtils";
import ServiceList from "../trainer/ServiceList";
import WideButton from "../common/WideButton";
import {currentRole, Roles} from "../../services/authService";

const ProfilePage = () => {

    const navigate = useNavigate();

    const [profileDetails, setProfileDetails] = useState({});

    useEffect(() => {
        getProfile()
            .then(response => {
                setProfileDetails(response.data);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });

    }, [navigate]);


    const handleEditServices = () => {
        console.log('edit services');
    }

    const handleEditPersData = () => {
        console.log('edit personal data');
    }

    const handleEditContacts = () => {
        console.log('edit contacts');
    }

    const handleEditAboutTrainer = () => {
        console.log('edit about');
    }

    const handleEditSkills = () => {
        console.log('edit skills');
    }

    return (
        <div className={styles.MainContainer}>
            <div className={styles.optionalBlocks}>
                {profileDetails ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Личные данные</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <div className={styles.whiteBoxContainer}>
                                <section className={styles.whiteBoxCard}>
                                    <span>Фамилия: {profileDetails?.personal?.lastName}</span>
                                    <span>Имя: {profileDetails?.personal?.firstName}</span>
                                    <span>Дата рождения: {profileDetails?.personal?.birthday}</span>
                                    <span>Пол: {profileDetails?.personal?.sex}</span>
                                </section>
                                <div className={styles.editButtonContainer}>
                                    <WideButton text="Редактировать" onClick={handleEditPersData} transparent={true}/>
                                </div>
                            </div>

                        </section>
                    </div>
                    : null
                }

                {profileDetails ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Контакты</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <div className={styles.whiteBoxContainer}>
                                <section className={styles.whiteBoxCard}>
                                    <span>Мобильный телефон: {profileDetails?.contact?.phone}</span>
                                    <span>Telegram: {profileDetails?.contact?.tgUsername}</span>
                                    <span>Адрес: {profileDetails?.contact?.location}</span>
                                </section>
                                <div className={styles.editButtonContainer}>
                                    <WideButton text="Редактировать" onClick={handleEditContacts} transparent={true}/>
                                </div>
                            </div>

                        </section>
                    </div>
                    : null
                }

                {currentRole() === Roles.TRAINER && profileDetails ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Обо мне</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <div className={styles.whiteBoxContainer}>
                                <section className={styles.whiteBoxCard}>
                                    <span>{profileDetails?.about}</span>
                                </section>
                                <div className={styles.editButtonContainer}>
                                    <WideButton text="Редактировать" onClick={handleEditAboutTrainer}
                                                transparent={true}/>
                                </div>
                            </div>
                        </section>
                    </div>
                    : null
                }

                {currentRole() === Roles.TRAINER && profileDetails ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Дисциплины</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <div className={styles.whiteBoxContainer}>
                                <section className={styles.whiteBoxCard}>
                                    <span>Фитнес: Персональный тренинг, Силовые, Кардио</span>
                                    <span>Йога: Йога для начинающих</span>
                                </section>
                                <div className={styles.editButtonContainer}>
                                    <WideButton text="Редактировать" onClick={handleEditSkills} transparent={true}/>
                                </div>
                            </div>
                        </section>
                    </div>
                    : null
                }

                {currentRole() === Roles.TRAINER && profileDetails ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Услуги</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <ServiceList services={profileDetails?.services}/>
                            <div className={styles.editButtonContainer}>
                                <WideButton text="Редактировать" onClick={handleEditServices} transparent={true}/>
                            </div>
                        </section>
                    </div>
                    : null
                }

            </div>

            <NavigationBar/>
        </div>
    );
};


export default ProfilePage;
