import React, {useEffect, useState} from 'react';
import styles from './ProfilePage.module.css';
import FavoriteTrainers from "./FavoriteTrainers";
import TrainingCard from "../common/TrainingCard";
import {useNavigate} from "react-router-dom";
import {findFavoriteTrainers, getShortProfile} from "../../services/clientService";
import {handleRestError} from "../../services/restUtils";
import {getPhoto} from "../../utils/PhotoUtil";
import ProfilePicture from "../common/ProfilePicture";
import {convertToBase64} from "../../utils/FileUtil";
import {findArchiveTrainings, findUpcomingTraining, updatePhoto} from "../../services/userService";
import BalanceCard from "./BalanceCard";
import pages from "../../pages";
import NavigationBar from "../common/NavigationBar";

const ProfilePage = () => {

    const navigate = useNavigate();

    const [clientProfileDto, setClientProfileDto] = useState({});
    const [upcomingTraining, setUpcomingTraining] = useState(null);
    const [favoriteTrainersData, setFavoriteTrainersData] = useState(null);
    const [archiveTrainings, setArchiveTrainings] = useState(null);

    useEffect(() => {
        getShortProfile()
            .then(response => {
                const clientProfileDto = response.data;
                setClientProfileDto(clientProfileDto);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });

    }, [navigate]);

    useEffect(() => {

        findFavoriteTrainers(1, 10)
            .then(response => setFavoriteTrainersData(response.data))
            .catch(error => handleRestError(error, navigate));

    }, [navigate]);

    useEffect(() => {
        findUpcomingTraining()
            .then(response => setUpcomingTraining(response.data))
            .catch(error => handleRestError(error, navigate));

    }, [navigate]);

    useEffect(() => {

        findArchiveTrainings(1, 3)
            .then(response => setArchiveTrainings(response.data))
            .catch(error => handleRestError(error, navigate));

    }, [navigate]);

    const handlePhotoChange = (event) => {
        if (clientProfileDto) {
            const setPhotoBase64 = (base64) => {
                updatePhoto(base64)
                    .then(_ => {
                        setClientProfileDto(prevState => {
                            return {
                                ...prevState,
                                photoBase64: base64
                            }
                        })
                    })
                    .catch(error => handleRestError(error, navigate));
            };
            convertToBase64(event, setPhotoBase64);
        }
    };

    return (
        <div className={styles.MainContainer}>
            <header className={styles.Header}>
                <h1 className={styles.ProfileTitle}>Мой профиль</h1>
            </header>

            <section className={styles.ProfileInfo}>
                <div>
                    <ProfilePicture src={getPhoto(clientProfileDto?.photoBase64)}
                                    alt="Аватар профиля"
                                    onChange={handlePhotoChange}
                    />
                </div>
                <div className={styles.profileDetails} onClick={() => navigate(pages.profileDetails)}>
                    <p className={styles.Name}>{clientProfileDto?.firstName} <br/>{clientProfileDto?.lastName}</p>
                    <img
                        className={styles.settingsIcon}
                        src={`/img/common/icons/arrow-black-right.svg`}
                        alt="Настройки"
                    />
                </div>
            </section>

            <div className={styles.balanceContainer}>
                <BalanceCard amount={clientProfileDto?.balance}/>
            </div>

            <div className={styles.optionalBlocks}>
                {upcomingTraining ?
                    <div className={styles.expandedBlock}>
                        <section className={styles.expandedBlockSection}>
                            <h2 className={styles.expandedBlockTitle}>Ближайшая активность</h2>
                            <TrainingCard id={upcomingTraining?.id}
                                          datetime={upcomingTraining?.start}
                                          trainerName={upcomingTraining?.trainerName}
                                          activityName={upcomingTraining?.activityName}
                            />
                        </section>
                    </div>
                    : null
                }

                {favoriteTrainersData?.length > 0 ?
                    <div className={styles.expandedBlock}>
                        <section className={styles.expandedBlockSection}>
                            <h2 className={styles.expandedBlockTitle}>Избранные тренеры</h2>
                            <FavoriteTrainers trainersData={favoriteTrainersData}/>
                        </section>
                    </div>
                    : null
                }

                {archiveTrainings?.length > 0 ?
                    <div className={styles.expandedBlock}>
                        <section className={styles.expandedBlockSection}>
                            <h2 className={styles.expandedBlockTitle}>Архив тренировок</h2>
                            <div className={styles.expandedBlockContent}>
                                {archiveTrainings?.map((session, index) => (
                                    <TrainingCard
                                        key={index}
                                        id={session?.id}
                                        activityName={session?.activityName}
                                        trainerName={session?.trainerName}
                                        datetime={session?.start}
                                    />
                                ))}
                            </div>
                        </section>
                    </div>
                    : null
                }

            </div>
            <NavigationBar/>
        </div>
    );
};


export default ProfilePage;
